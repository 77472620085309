.notification__container {
    @apply text-sm fixed box-border;
    z-index: 999999;
}

.top-right {
    top: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
}

.bottom-right {
    bottom: 12px;
    right: 12px;
    transition: transform 0.6s ease-in-out;
    animation: toast-in-right 0.7s;
}

.top-left {
    top: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
}

.bottom-left {
    bottom: 12px;
    left: 12px;
    transition: transform 0.6s ease-in;
    animation: toast-in-left 0.7s;
}

.notification {
    @apply flex items-center justify-between relative overflow-hidden pointer-events-auto rounded-md;
    transition: 0.3s ease;
    opacity: 0.9;
    min-width: 300px;
    max-height: 100px;
    min-height: 50px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04),
        0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.notification:hover {
    @apply cursor-pointer;
    opacity: 1;
    box-shadow: 0 0 12px #fff;
}

.notification__message {
    @apply w-full pl-2 pr-4 overflow-hidden text-sm font-medium leading-8;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.notification__image {
    @apply flex;
}

.toast {
    @apply h-auto;
    min-width: 365px;
    width: 100%;
    padding: 12px 16px 12px 12px;
    margin-bottom: 14px;
}

.toast__danger {
    background: #F8D7DA;
    color: #721B23;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);

    .notification-close {
        svg {
            fill: #721B23;
        }
    }
}

.toast__success {
    color: #005944;
    background: #DAF7F0;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

.notification__container button {
    @apply outline-none border-none cursor-pointer border-0;
    opacity: 0.8;
    background: inherit;
}

@keyframes toast-in-right {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes toast-in-left {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}