.job__planCard {
    border: 1px solid #CBD5E0;
    box-sizing: border-box;
    border-radius: 6px;
    height: 5.125rem;
    padding: 1.25rem;

    &--checked {
        box-shadow: 0px 0px 0px 2px #08D4A5;
        border: 1px solid transparent;
    }
}